import { isMobileDevice } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_turma } from "../../../../models";

export const Turma = () => {
    function drawColors(e: any) {
        return (
            <div style={{
                backgroundColor: e.turmacor,
                maxWidth: isMobileDevice() ? '100%' : '10px',
                width: '100%',
                height: isMobileDevice() ? '3px' : '30px',
                borderRadius: '2px'
            }} />
        )
    }

    const columns = [
        { field: 'turmacor', header: '', notSortable: true, body: (e: any) => drawColors(e) },
        { field: 'turmaid', header: 'Código' },
        { field: 'turmadescricao', header: 'Descrição' },
        { field: 'turmavagas', header: 'Vagas' },
        { field: 'turmatempo', header: 'Tempo (Minutos)' },
        { field: 'local.localdescricao', header: 'Local' },
        { field: 'modalidade.modaliddescricao', header: 'Modalidade' },
        { field: 'turmaativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_turma} columns={columns} />
    )
}