import { Col, Container } from "react-bootstrap";
import styled from "styled-components";

export const FormContainer = styled(Container)`
    padding: 2rem 1.5rem;
    max-width: 800px;
`
export const ImgLogo = styled.img`
    max-width: 225px;
    margin-bottom: 25px;
`
export const ImgEtapa = styled.img`
    display: flex;
    max-height: 100px;
    width: auto;
    margin-top: -20px;
    margin-bottom: 15px;
`

export const VbCol = styled(Col)`
    padding-right: .5rem;
    padding-left: .5rem;
    margin-bottom: 1rem;
`
export const Label = styled.div`
    display: flex;
    justify-content: center;
    padding: 3px 40px;
    background-color: rgba(80, 80, 80, .4);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin-top: -10px;
`
export const Title = styled.div`
    display: flex;
    justify-content: center;
    font-size: 18px;
`
export const Description = styled.div`
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: center;
`