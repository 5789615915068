import KanbanBoard, { Column } from "../../../components/VbKanban/Kanban";
import { useRef } from "react";
import { LuMessagesSquare } from "react-icons/lu";
import { FiltroLead } from "../../Commons/FiltroLead";
import { ButtonCard } from "../../../commons/utils";
import { FaWhatsapp, FaPause, FaQuestion, FaPlus, FaCopy } from "react-icons/fa6";
import { RiUserSearchFill } from "react-icons/ri";
import { LeadItem } from "../../Cadastros/Pessoas/LeadItem";
import { VscListFlat } from "react-icons/vsc";
import { BiSolidSend } from "react-icons/bi";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { CadastroPadrao } from "../../../components/CadastroPadrao";
import { model_lead_curioso, model_lead_enrolado } from "../../../models";
import copy from "copy-to-clipboard";
import { getApi } from "../../../api/address";

export const lead_status: Column[] = [
    { id: "lead", title: "LEAD", color: '#1d5db6', icon: <RiUserSearchFill />, value: 1 },
    { id: "interacao", title: "EM INTERAÇÃO", color: '#c46c29', icon: <LuMessagesSquare />, value: 2 },
    { id: "enrolado", title: "ENROLADO", color: '#898b15', icon: <FaPause />, value: -1 },
    { id: "cadastrando", title: "CADASTRANDO", color: '#0c9110', icon: <FaPlus />, value: 3 },
    { id: "curioso", title: "CURIOSO", color: '#910c0c', icon: <FaQuestion />, value: 999 }
];

export const ControleLead = () => {
    const auth = useAuth();

    const refKanbam = useRef<any>();
    const refFiltro = useRef<any>();
    const refDetalhes = useRef<any>();
    const refEnrolado = useRef<any>();
    const refCurioso = useRef<any>();

    async function updateStatus(empid: any, id: any, situacao: any) {
        try {
            await auth.post('/lead/alterarSituacao', { empid, leadid: id, leadsituacao: situacao });
            toast.success('Registro atualizado.');
        } catch (error) {
            console.log(error);
        }
    }

    function createButton(hint: string, color: string, icon: JSX.Element, onClick: () => void) {
        return (
            <ButtonCard hint={hint} color={color} iconcenter={icon} onClick={onClick} className='p-1 me-1' />
        );
    }

    function onGetData(data: any) {
        const newArray: any = [];
        refKanbam.current.setTasks([]);

        data.forEach((item: any) => {
            const { empid, empnome } = item.empresa;
            const { leadid, leadnome, leadcontato, leadsituacao, leadmotivo, tipoProspeccao } = item;

            const id = `${empid} - ${leadid}`;
            const content = `${leadid} - ${leadnome}`;
            const columnId = leadsituacao === 1 ? "lead" : leadsituacao === 2 ? "interacao" : leadsituacao === 3 ? "cadastrando" : leadsituacao === -1 ? "enrolado" : leadsituacao === 999 ? "curioso" : "";
            const details = empnome;

            const infoAdditional = (leadsituacao === 999 || leadsituacao === -1) ? `Motivo: ${leadmotivo}` : null;

            const color = lead_status.find(info => info.value === leadsituacao)?.color;

            const functions = (
                <>
                    {((leadsituacao === 1) || (leadsituacao === -1)) && createButton('Interagir', color, <BiSolidSend style={{ fontSize: '16px' }} />,
                        () => updateStatus(empid, leadid, 2).then(() => refFiltro.current.refresh()))}

                    {leadsituacao === 2 && createButton('Iniciar Cadastro (Copiar Link)', color, <FaPlus style={{ fontSize: '16px' }} />,
                        () => updateStatus(empid, leadid, 3).then(() => refFiltro.current.refresh()))}

                    {leadsituacao === 3 && createButton('Copiar Link de Cadastro', color, <FaCopy style={{ fontSize: '16px' }} />,
                        () => {
                            copy(`https://crm${getApi()}.vbfit.com.br/prospect-form?u_vbfit=${auth.usuarioEmpresa.usuemptoken}&empidlead=${empid}&leadid=${leadid}&nome=${leadnome.replace(/\s+/g, '%20')}&telefone=${leadcontato.replace(/\s+/g, '%20')}&tipoprospeccao=${tipoProspeccao.tipprospecid}`);
                            toast.success('URL copiada com sucesso.');
                        }
                    )}

                    {leadsituacao !== 999 && (
                        <>
                            {createButton('Curioso', color, <FaQuestion style={{ fontSize: '16px' }} />,
                                () => refCurioso.current.openModal({ ...model_lead_curioso.base, leadid, empid }))}
                            {leadsituacao !== -1 && createButton('Pausar', color, <FaPause style={{ fontSize: '16px' }} />,
                                () => refEnrolado.current.openModal({ ...model_lead_enrolado.base, leadid, empid }))}
                        </>
                    )}

                    {createButton('Observações', color, <VscListFlat style={{ fontSize: '16px' }} />,
                        () => refDetalhes.current.openModal(empid, leadid))}

                    {createButton('Abrir WhatsApp Web', color, <FaWhatsapp style={{ fontSize: '16px' }} />,
                        () => window.open(`https://wa.me/+55${leadcontato.replace(/\D/g, "")}`, '_blank'))}
                </>
            );

            newArray.push({ id, content, columnId, details, infoAdditional, functions });
        });

        refKanbam.current.setTasks(newArray);
    }

    return (
        <>
            <FiltroLead ref={refFiltro} onGetData={onGetData} style={{ padding: '0px 20px' }} />
            <KanbanBoard ref={refKanbam} columnsId={lead_status.map(item => item.id)} updateStatus={updateStatus} status={lead_status} />
            <CadastroPadrao ref={refCurioso} model={model_lead_curioso} success={() => refFiltro.current.refresh()} />
            <CadastroPadrao ref={refEnrolado} model={model_lead_enrolado} success={() => refFiltro.current.refresh()} />
            <LeadItem ref={refDetalhes} />
        </>
    );
}
