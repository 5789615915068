import { formatDataHora } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { LabelDataTable } from "../../../../components/VbDataTable";
import { model_feedback } from "../../../../models";
import { COLOR_BLUE, COLOR_GREEN, COLOR_RED } from "../../../../theme/styles";

export const Feedback = () => {

    const styleTipo = (tipo: any) => {
        return (
            <LabelDataTable color={tipo.toUpperCase() === 'RECLAMAÇÃO' ? COLOR_RED : tipo.toUpperCase() === 'SUGESTÃO' ? COLOR_BLUE : COLOR_GREEN}>
                {tipo.toUpperCase()}
            </LabelDataTable >
        )
    }

    const columns = [
        { field: 'sacid', header: 'Código' },
        { field: 'sactipo', header: 'Tipo', body: (e: any) => styleTipo(e.sactipo), style: { width: '135px' } },
        { field: 'sacdescricao', header: 'Descrição', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'sacdatahora', header: 'Data', body: (e: any) => formatDataHora(e.sacdatahora) }
    ];

    return (
        <ConsultaPadrao model={model_feedback} columns={columns} sortfield={'sacdatahora'} sortorder={-1} />
    )
}