import { Dropdown, Nav, NavDropdown } from "react-bootstrap"
import styled from "styled-components"
import { navigate } from "../../commons/utils"

export const VbNavDropdown = styled(NavDropdown)`
    font-size: 16px;
    margin-right: 16px;
    user-select: none;
`
export const VbNavIcon = styled.div`
    display: flex;
    margin-right: 5px;
    font-size: 18px;
`
export const VbDivider = styled.div`
    margin: 10px;
    margin-left: 14px;
    height: 1px;
    width: 200px;
    background-color: gray;
`
const NavDropdownItemStyled = styled(NavDropdown.Item)`
    font-size: 14px;
    padding: 2px 20px;
    border-radius: 5px;

    &:active {
        background-color: #fd6e27;
    }
`
export const DropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    align-items: center;
    background-color: transparent !important; /* Importante para sobrescrever estilos padrão */
    color: rgba(255, 255, 255, 0.65);
    border-color: rgba(255, 255, 255, 0.65) !important;
    font-size: 14px;
    min-width: 200px;

    &:hover {
        background-color: transparent !important; /* Importante para sobrescrever estilos padrão */
        color: rgba(255, 255, 255, 0.9);
        border-color: rgba(255, 255, 255, 0.9) !important;;
    }
`;

export const DropdownItem = styled(Dropdown.Item)`
    background: transparent;
    border: none;
    height: 30px;
    width: 100%;
    font-size: 14px;
    text-align: left;
    padding-left: 1rem;
    min-width: 200px;
    
    &:hover {
        background-color: #2B3035;
    }

    &:active {
        background-color: #fd6e27;
    }
`


export const VbNavDropdownItem = (props: any) => {
    return (
        <NavDropdownItemStyled onClick={() => navigate(props.to)}>
            {props.children}
        </NavDropdownItemStyled>
    )
}

const NavLinkStyled = styled(Nav.Link)`
    font-size: 16px;
    margin-right: 16px;
`

export const VbNavItem = (props: any) => {
    return (
        <NavLinkStyled onClick={() => { props.onClick ? props.onClick() : navigate(props.to) }}>
            {props.children}
        </NavLinkStyled >
    )
}

const NavDropdownContainer = styled.div`
    margin-bottom: 10px;
`

const NavDropdownHeaderStyled = styled(NavDropdown.Header)`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600; 
    padding-bottom: 4px;
`

export const VbNavDropdownHeader = (props: any) => {
    return (
        <NavDropdownContainer>
            <NavDropdownHeaderStyled>
                <VbNavIcon>{props.icon}</VbNavIcon>{props.title}
            </NavDropdownHeaderStyled>
            {props.children}
        </NavDropdownContainer>
    )
}