import { formatDataHora } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_log } from "../../../../models";

export const Logs = () => {
    const columns = [
        { field: 'logid', header: 'Código' },
        { field: 'logdata', header: 'Data', body: (e: any) => formatDataHora(e.logdata)},
        { field: 'loglog', header: 'Log' },
        { field: 'usuario.usunome', header: 'Usuário' }     
    ];

    return (
        <ConsultaPadrao model={model_log} columns={columns} sortfield={'logdata'} sortorder={-1} />
    )
}