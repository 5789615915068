import React, { useImperativeHandle, useState } from "react";
import { Collapse, Grid, useTheme } from '@mui/material';
import { isMobileDevice } from "../../commons/utils";

const DetailsChart = (props: any, ref: any) => {
    const [filtered, setFiltered] = useState(false);
    const theme = useTheme();

    useImperativeHandle(ref, () => ({
        setFiltered
    }))

    return (
        <Grid
            item
            xs={(filtered || isMobileDevice()) ? 12 : props.xs ? props.xs : 6}
            style={{
                transition: theme.transitions.create("all", { duration: 400 })
            }}
        >
            {props.children}
            <Collapse in={filtered} >
                {props.detail}
            </Collapse >
        </Grid>
    );
}

export default React.forwardRef(DetailsChart);