import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_lead_detalhe } from "../../../../models";
import { formatDataHora } from "../../../../commons/utils";

export const LeadItem = forwardRef((props: any, ref: any) => {
    const [show, setShow] = useState(false);
    const [model, setModel] = useState<any>();

    const columns = [
        { field: 'leaditemcadastro', header: 'Cadastro', body: (e: any) => formatDataHora(e.leaditemcadastro) },
        { field: 'leaditemcomentario', header: 'Observação' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function closeModal() {
        setShow(false);
    }

    function openModal(_empdid: any, _leadid: any) {
        let _model = { ...model_lead_detalhe };

        _model.api_get = _model.api_get + '?leadid=' + _leadid + '&empid=' + _empdid;
        _model.base = {
            ..._model.base,
            lead: {
                empid: _empdid,
                leadid: _leadid
            },
            leaditemid: 0
        }

        setModel(_model);
        setShow(true);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    return (
        model &&
        <Modal
            show={show}
            onHide={closeModal}
            centered={false}
            size='xl'
        >
            <ConsultaPadrao
                model={model}
                columns={columns}
                scrollHeight='500px'
            />
        </Modal>
    )
})