import { formatDataHora } from "../../../commons/utils";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_report_faltas } from "../../../models";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { COLOR_GREEN } from "../../../theme/styles";

export const ReportFaltas = () => {
    const columns = [
        { field: 'dataagenda', header: 'Data', body: (e: any) => formatDataHora(e.dataagenda) },
        { field: 'prospect.prospnome', header: 'Prospect' },
        { field: 'prospect.prosptelefone', header: 'WhatsApp' },
        { field: 'aula.turma.turmadescricao', header: 'Turma' },
        { field: 'aula.turma.modalidade.modaliddescricao', header: 'Modalidade' },
        { field: 'usuario.usunome', header: 'Consultor' },
        { field: 'functions', style: { minWidth: '4rem', width: '4rem' } }
    ];

    function functions(e: any) {
        return ([
            { label: 'Abrir WhatsApp Web', click: () => window.open('https://wa.me/+55' + e.prospect.prosptelefone.replace(/\D/g, ""), '_blank'), icon: <WhatsAppIcon />, color: COLOR_GREEN }
        ])
    }

    return (
        <ConsultaPadrao model={model_report_faltas} columns={columns} functions={functions} sortfield={'dataagenda'} sortorder={-1} />
    )
}