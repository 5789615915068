import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_usuario } from "../../../../models";

export const Usuario = () => {
    const columns = [
        { field: 'usuid', header: 'Código' },
        { field: 'usunome', header: 'Nome' },
        { field: 'usuemail', header: 'E-mail' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_usuario} columns={columns} />
    )
}