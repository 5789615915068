import { createContext, useState, useEffect } from 'react';
import { IAuthProvider, IContext, IUser } from './types';
import { GetRequest, LoginRequest, getUserLocalStorage, setUserLocalStorage, PostRequest, DeleteRequest } from './util';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<IUser | null>();

    useEffect(() => {
        const user = getUserLocalStorage()

        if (user) {
            setUser(user);
        }
    }, []);

    async function alterUser(usuarioEmpresa: any) {
        if (user?.usuarioEmpresa.empresa.empid !== usuarioEmpresa.empresa.empid) {
            let payload = { ...user };

            payload.usuarioEmpresa = usuarioEmpresa;

            setUser(payload);
            setUserLocalStorage(payload);

            window.location.reload();
        }
    }

    async function authenticate(username: string, password: string) {
        const response = await LoginRequest(username, password)

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            const payload = {
                usuid: response.dados.usuario.usuid,
                usunome: response.dados.usuario.usunome,
                usuarioEmpresa: response.dados.usuarioEmpresa[0],
                empresas: response.dados.usuarioEmpresa
            }

            setUser(payload);
            setUserLocalStorage(payload);
        } else
            throw response;
    }

    function logout() {
        setUser(null);
        setUserLocalStorage(null);
    }

    async function get(url: string) {
        const response = await GetRequest(url, user?.usuarioEmpresa.usuemptoken);

        if ((response.response) && (response.response.status === 406)) {
            logout();
            return response
        }

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function post(url: string, data: any) {
        const response = await PostRequest(url, user?.usuarioEmpresa?.usuemptoken, data);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function deleta(url: string, data: any) {
        const response: any = await DeleteRequest(url, user?.usuarioEmpresa.usuemptoken, data);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    return (
        <AuthContext.Provider value={{ ...user, authenticate, logout, get, post, deleta, alterUser }}>
            {children}
        </AuthContext.Provider>
    )
}