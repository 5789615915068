import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_usuario_empresa } from "../../../../models";

export const UsuarioEmpresa = () => {
    const columns = [
        { field: 'usuario.usuid', header: 'Cód. Usuário' },
        { field: 'usuario.usunome', header: 'Usuário' },
        { field: 'privilegio.privid', header: 'Cód. Privilégio' },
        { field: 'privilegio.privdescricao', header: 'Privilégio' },
        { field: 'usuempativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '4rem', width: '4rem' } }
    ];

    return (
        <ConsultaPadrao model={model_usuario_empresa} columns={columns} />
    )
}