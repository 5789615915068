import { Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { VbSelect } from "../../../components/VbSelect";
import { VbInput } from "../../../components/VbInput";
import { model_lead, model_usuario } from "../../../models";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { formatDataCompare } from "../../../commons/utils";

export const FiltroLead = forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const [data, setData] = useState<any>();

    const refConsultor = useRef<any>();
    const refRetornoInicial = useRef<any>();
    const refRetornoFinal = useRef<any>();

    function refresh() {
        change(true);
    }

    useImperativeHandle(ref, () => ({
        refresh
    }))

    async function getDados(refresh: boolean, consultores?: any, iRetorno?: any, fRetorno?: any) {
        let _data: any;

        if (!refresh && data)
            _data = data;
        else {
            await auth.get(model_lead.api_get)
                .then((response: any) => {
                    setData(response.data.dados);
                    _data = response.data.dados;
                })
                .catch((error: any) => {
                    console.log(error)
                    toast.error('Erro ao executar a operação.');
                })
        }

        let _consultores = consultores;
        let _iRetorno = iRetorno;
        let _fRetorno = fRetorno;

        if (!_consultores)
            _consultores = refConsultor.current.getValue();

        if (!_iRetorno && (_iRetorno !== ''))
            _iRetorno = refRetornoInicial.current.getValue();

        if (!_fRetorno && (fRetorno !== ''))
            _fRetorno = refRetornoFinal.current.getValue();


        if (_consultores && (_consultores.length > 0)) {
            const usuarioValues = _consultores.map((consultor: any) => consultor.value);
            _data = _data.filter((item: any) => usuarioValues.includes(item.usuario.usuid));
        }

        if (_iRetorno && (_iRetorno !== ''))
            _data = _data.filter((item: any) => formatDataCompare(item.leadretorno) >= _iRetorno);

        if (_fRetorno && (_fRetorno !== ''))
            _data = _data.filter((item: any) => formatDataCompare(item.leadretorno) <= _fRetorno);

        return _data;
    }

    function change(refresh: boolean, consultores?: any, iCadastro?: any, fCadastro?: any) {
        getDados(refresh, consultores, iCadastro, fCadastro).then((data: any) => {
            if (props.onGetData)
                props.onGetData(data);
        })
    }

    function changeConsultor(_newValue: any) { change(false, _newValue) }
    function changeRetornoInicial(_newValue: any) { change(false, null, _newValue) }
    function changeRetornoFinal(_newValue: any) { change(false, null, null, _newValue) }

    useEffect(() => {
        change(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={2} style={{ ...props.style }}>
            <Grid item xs={6}>
                <VbSelect ref={refConsultor} id='filterTipoProspeccao' label='Consultor' model={model_usuario} onAfterChange={changeConsultor} isMulti={true} />
            </Grid>
            <Grid item xs={3}>
                <VbInput ref={refRetornoInicial} id="iRetorno" type="date" label="Retorno Inicial" onAfterChange={changeRetornoInicial} />
            </Grid>
            <Grid item xs={3}>
                <VbInput ref={refRetornoFinal} id="fRetorno" type="date" label="Retorno Final" onAfterChange={changeRetornoFinal} />
            </Grid>
        </Grid>
    )
})