import { forwardRef } from "react";
import { Description, FormContainer, ImgEtapa, ImgLogo, Label } from "../commons";
import { Row } from "react-bootstrap";
import LogoFull from "../../../Images/logo_full.png"
import LogoEtapa from "../../../Images/cadastro_concluido.png"

export const SuccessPub = forwardRef((props: any, ref) => {
    return (
        <FormContainer className="d-flex flex-column">
            <Row className="mb-3 justify-content-center">
                <ImgLogo src={LogoFull} />
                <Row className="justify-content-center">
                    <ImgEtapa src={LogoEtapa} />
                </Row>
                <Row className="mb-3 justify-content-center">
                    <Label>CADASTRO CONCLUÍDO 😁</Label>
                </Row>
                <Description>A equipe VBFit agradece!</Description>
            </Row>
        </FormContainer>
    )
});