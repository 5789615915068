import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_historico_agendamento } from "../../../models";
import { formatDataHora } from "../../../commons/utils";
import { VbSwitch } from "../../../components/VbSwitch";

export const HistoricoAgendamentoProspect = forwardRef((props: any, ref) => {
    const [show, setShow] = useState(false);
    const [prospect, setProspect] = useState<any>();

    function openModal(_prospect: any) {
        setProspect(_prospect);
        setShow(true);
    }

    function closeModal() {
        setShow(false);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }))

    const columns = [
        { field: 'dataagenda', header: 'Data Agenda', body: (e: any) => formatDataHora(e.dataagenda) },
        { field: 'aula.turma.turmadescricao', header: 'Turma' },
        { field: 'agendapresente', header: 'Presente', body: (e: any) => <VbSwitch checked={e.agendapresente === 1} disabled />, style: { width: '100px' } },
    ];

    return (
        prospect &&
        <Modal show={show} onHide={closeModal} centered={true} size='xl'>

            <ConsultaPadrao
                model={{
                    ...model_historico_agendamento,
                    api_get: '/prospect/agendas?empidprosp=' + prospect?.empid + '&prospid=' + prospect?.prospid,
                    name: 'Histórico Agendamento ( ' + prospect.prospnome + ' )'
                }}
                columns={columns}
                scrollHeight='500px'
            />

        </Modal>
    )
})