import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Row, Col, Dropdown } from 'react-bootstrap';
import Logo from '../../Images/LogoPequena.png'
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { IoPeopleSharp } from "react-icons/io5";
import { RiMenuAddFill } from "react-icons/ri";
import { AppRoutes } from '../../routes';
import { DropdownItem, DropdownToggle, VbNavDropdown, VbNavDropdownHeader, VbNavDropdownItem, VbNavItem } from '../../components/VbNavbar';
import { FaBuilding } from "react-icons/fa";
import { FaComputer } from 'react-icons/fa6';
import { FaChartPie } from "react-icons/fa";

export const Principal = () => {
    const auth = useAuth();

    return (
        <div style={{ height: '100vh' }}>
            <Navbar expand="lg" className="bg-body-tertiary" style={{ padding: '2px', userSelect: 'none' }}>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={Logo} style={{ maxHeight: '40px' }} alt='' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto" />
                        <Nav className="me-auto">
                            {/*<VbNavItem to="/dashboard">Dashboard</VbNavItem>*/}
                            <VbNavDropdown title="Dashboards">
                                <Row>
                                    <Col>
                                        <VbNavDropdownHeader icon={<FaChartPie />} title={'Prospects'}>
                                            <VbNavDropdownItem to="/">Geral</VbNavDropdownItem>
                                            <VbNavDropdownItem to="/dashboard/convertidos_professor">Convertidos Por Professor</VbNavDropdownItem>
                                        </VbNavDropdownHeader>
                                    </Col>
                                </Row>
                            </VbNavDropdown>
                            <VbNavDropdown title="Cadastros">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <Col>
                                            <VbNavDropdownHeader icon={<IoPeopleSharp />} title={'Cliente'}>
                                                <VbNavDropdownItem to="/lead">Lead</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/prospect">Prospect</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/usuario">Usuário</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/usuarioempresa">Usuário Empresa</VbNavDropdownItem>
                                            </VbNavDropdownHeader>
                                            <VbNavDropdownHeader icon={<RiMenuAddFill />} title={"Outros"}>
                                                <VbNavDropdownItem to="/aula">Aula</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/local">Local</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/modalidade">Modalidade</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/motivo">Motivo</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/privilegio">Privilégio</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/tipoprospeccao">Tipo Prospecção</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/turma">Turma</VbNavDropdownItem>
                                            </VbNavDropdownHeader>
                                        </Col>
                                        
                                        <Col>
                                            <VbNavDropdownHeader icon={<IoPeopleSharp />} title={'Projeto Vitor'}>
                                                <VbNavDropdownItem to="/metodo">Método</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/exercicio">Exercício</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/especificacao">Especificação</VbNavDropdownItem>
                                            </VbNavDropdownHeader>
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </VbNavDropdown>
                            <VbNavDropdown title="Consultas">
                                <div style={{ width: '400px' }}>
                                    <Row>
                                        <Col>
                                            <VbNavDropdownHeader icon={<FaComputer />} title={"Plataforma"}>
                                                <VbNavDropdownItem to="/logs">Logs</VbNavDropdownItem>
                                                <VbNavDropdownItem to="/feedbacks">Feedbacks</VbNavDropdownItem>
                                            </VbNavDropdownHeader>
                                            <VbNavDropdownHeader icon={<RiMenuAddFill />} title={"Doar é Saúde"}>
                                                <VbNavDropdownItem to="/doacoes">Doações</VbNavDropdownItem>
                                            </VbNavDropdownHeader>
                                        </Col>
                                    </Row>
                                </div>
                            </VbNavDropdown>
                            <VbNavDropdown title="Kanbans">
                                <Row>
                                    <Col>
                                        <VbNavDropdownItem to="/controlelead">Controle Lead</VbNavDropdownItem>
                                        <VbNavDropdownItem to="/controleprospeccao">Controle Prospecção</VbNavDropdownItem>

                                    </Col>
                                </Row>
                            </VbNavDropdown>
                            <VbNavItem to="/agenda">Agenda</VbNavItem>
                            <VbNavDropdown title="Relatórios">
                                <Row>
                                    <Col>
                                        <VbNavDropdownItem to="/report_faltas">Faltas</VbNavDropdownItem>
                                    </Col>
                                </Row>
                            </VbNavDropdown>
                            <VbNavItem onClick={() => auth.logout()}>Sair</VbNavItem>
                        </Nav>

                        {(auth.empresas.length > 1) &&
                            <Nav className="me-auto">
                                <VbNavItem>
                                    <Dropdown>
                                        <DropdownToggle style={{ paddingTop: '14px', paddingBottom: '4px' }}>
                                            <p style={{ position: 'absolute', fontSize: '10px', top: 0, left: 14 }}>Selecione a empresa:</p>
                                            <FaBuilding style={{ marginRight: '10px' }} />
                                            {auth?.usuarioEmpresa?.empresa?.empnome}
                                        </DropdownToggle>

                                        <Dropdown.Menu>
                                            {
                                                auth.empresas.map((usuEmp: any, index: any) => (
                                                    <DropdownItem key={index} onClick={() => auth.alterUser(usuEmp)}>{usuEmp.empresa.empnome}</DropdownItem>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </VbNavItem>
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <AppRoutes />
        </div >
    )
}