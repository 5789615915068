import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { model_agenda } from "../../../models";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { Modal } from "react-bootstrap";
import { formatDataHora } from "../../../commons/utils";
import { COLOR_PRIMARY } from "../../../theme/styles";
import { LuFileCheck } from "react-icons/lu";
import { GenerateAnamnese } from "../../Cadastros/Pessoas/Prospect/Anamnese";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { PDFViewer } from "../../../components/PDFViewer";

export const AulasFuturas = forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const [show, setShow] = useState(false);
    const refAnamnese = useRef<any>();

    const columns = [
        { field: 'dataagenda', header: 'Data', body: (e: any) => formatDataHora(e.dataagenda) },
        { field: 'prospect.prospnome', header: 'Prospect' },
        { field: 'aula.turma.modalidade.modaliddescricao', header: 'Modalidade' },
        { field: 'functions', style: { minWidth: '4rem', width: '4rem' } }
    ];

    function closeModal() {
        setShow(false);
    }

    function openModal() {
        setShow(true);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    async function DownloadAnamnese(row: any) {
        await auth.get('/prospect/anamnese?empidprosp=' + row.empid + '&prospid=' + row.prospect.prospid)
            .then((response: any) => {
                GenerateAnamnese(row.prospect, response.data.dados).then((anamnese: any) => refAnamnese.current.openModal(anamnese));
            })
            .catch((error: any) => {
                console.log(error)
            })
    }

    function functions(row: any) {
        return ([
            {
                label: 'Baixar Anamnese Respondida',
                click: () => DownloadAnamnese(row),
                icon: <LuFileCheck style={{ fontSize: '24px', padding: '2px' }} />,
                color: COLOR_PRIMARY
            }
        ])
    }

    return (
        <>
            <Modal show={show} onHide={closeModal} centered={false} size='xl'>
                <ConsultaPadrao
                    model={{ ...model_agenda, name: 'Aulas Futuras' }}
                    columns={columns}
                    scrollHeight='500px'
                    sortfield='dataagenda'
                    functions={functions}
                    disableEdit
                    defaultFilter={(_data: any) => {
                        const now = new Date();

                        return _data.filter((item: any) => {
                            const dataAgenda = new Date(item.dataagenda);
                            return (item.agendapresente === 0) && (dataAgenda >= now);
                        });
                    }}
                />
            </Modal>
            <PDFViewer ref={refAnamnese} />
        </>
    );
})