import { useRef } from "react"
import { ageCalculator, isMobileDevice } from "../../commons/utils";
import { Grid } from "@mui/material";
import { prospect_status } from "../Kanbans/ControleProspeccao";
import { ProspectChart } from "./ProspectChart";
import { FiltroProspect } from "../Commons/FiltroProspect";

export const Dashboard = () => {
    const chartRefIdade = useRef<any>();
    const chartRefSexo = useRef<any>();
    const chartRefTipoProspeccao = useRef<any>();
    const chartRefSituacao = useRef<any>();
    const refFiltro = useRef<any>();


    function agruparIdade(idade: number): string {
        const intervalo = 5;
        const inicioIntervalo = Math.floor(idade / intervalo) * intervalo;
        const idadeAgrupada = `${inicioIntervalo}~${inicioIntervalo + intervalo - 1} anos`;

        return idadeAgrupada;
    }

    function intToSexo(sexo: number) {
        return sexo === 0 ? 'Masculino' : sexo === 1 ? 'Feminino' : 'Prefiro Não Informar'
    }

    function orderNascimento(a: any, b: any) {
        var a_nascimento = Number(new Date(a.prospnascimento));
        var b_nascimento = Number(new Date(b.prospnascimento));

        return b_nascimento - a_nascimento
    }

    function onGetData(data: any) {
        const listIdade: any = {};
        const listSexo: any = {};
        const listTipoProspeccao: any = {};
        const listSituacao: any = {};

        data.sort(orderNascimento);

        for (const item of data) {
            // Idade
            var idade = ageCalculator(item.prospnascimento);
            var idade_desc = agruparIdade(idade);

            if (idade_desc in listIdade)
                listIdade[idade_desc]++
            else
                listIdade[idade_desc] = 1;

            //Sexo
            var sexo = intToSexo(item.prospsexo);

            if (sexo in listSexo)
                listSexo[sexo]++
            else
                listSexo[sexo] = 1;

            //Tipo Prospecção
            var tipoProspeccao = item.tipoProspeccao.tipprospecdescricao;

            if (tipoProspeccao in listTipoProspeccao)
                listTipoProspeccao[tipoProspeccao]++
            else
                listTipoProspeccao[tipoProspeccao] = 1;

            //Situação
            var situacao: any = prospect_status.find((status: any) => status.value === item.controleProspeccao.contprospsituacao);

            if (situacao.title in listSituacao)
                listSituacao[situacao.title]++
            else
                listSituacao[situacao.title] = 1;
        }

        //Idade
        const object_idade: any = Object.keys(listIdade);
        chartRefIdade?.current.setData(data);
        chartRefIdade?.current.setLabels(object_idade);
        chartRefIdade?.current.setSeries(object_idade.map((idade: any) => listIdade[idade]));

        //Sexo
        const object_sexo: any = Object.keys(listSexo);
        chartRefSexo?.current.setData(data);
        chartRefSexo?.current.setLabels(object_sexo);
        chartRefSexo?.current.setSeries(object_sexo.map((sexo: any) => listSexo[sexo]));

        //Tipo Prospeccao
        const object_tipoProspeccao: any = Object.keys(listTipoProspeccao);
        chartRefTipoProspeccao?.current.setData(data);
        chartRefTipoProspeccao?.current.setLabels(object_tipoProspeccao);
        chartRefTipoProspeccao?.current.setSeries(object_tipoProspeccao.map((tipoProspeccao: any) => listTipoProspeccao[tipoProspeccao]));

        //Situação
        const object_situacao: any = Object.keys(listSituacao);
        chartRefSituacao?.current.setData(data);
        chartRefSituacao?.current.setLabels(object_situacao);
        chartRefSituacao?.current.setSeries(object_situacao.map((situacao: any) => listSituacao[situacao]));
    };


    function filterIdade(data: any, value: any) {
        return data.filter((item: any) => {
            var idade = ageCalculator(item.prospnascimento);
            var idade_desc = agruparIdade(idade);

            return idade_desc === value
        });
    }

    function filterSexo(data: any, value: any) {
        return data.filter((item: any) => {
            var sexo = intToSexo(item.prospsexo);
            return sexo === value
        });
    }

    function filterTipoProspeccao(data: any, value: any) {
        return data.filter((item: any) => { return item.tipoProspeccao.tipprospecdescricao === value });
    }

    function filterSituacao(data: any, value: any) {
        return data.filter((item: any) => {
            var situacao: any = prospect_status.find((status: any) => status.value === item.controleProspeccao.contprospsituacao);
            return situacao.title === value
        });
    }

    return (
        <Grid container spacing={2} sx={{ padding: isMobileDevice() ? '1rem' : '2rem 8rem' }}>
            <FiltroProspect ref={refFiltro} onGetData={onGetData} style={{ paddingLeft: '16px' }} />
            <ProspectChart
                ref={chartRefIdade}
                title={'PROSPECTS POR IDADE'}
                filter={filterIdade}
            />
            <ProspectChart
                ref={chartRefSexo}
                title={'PROSPECTS POR SEXO'}
                filter={filterSexo}
            />
            <ProspectChart
                ref={chartRefTipoProspeccao}
                title={'PROSPECTS POR TIPO PROSPECCAO'}
                filter={filterTipoProspeccao}
            />
            <ProspectChart
                ref={chartRefSituacao}
                title={'PROSPECTS POR SITUAÇÃO'}
                filter={filterSituacao}
            />
        </Grid >
    )
}