import ReactDOM from 'react-dom/client';
import { VbModal } from '../VbModal';

export function confirm(message: string) {
    return new Promise((resolve, reject) => {
        const handleConfirm = () => {
            resolve(true);
            closeModal();
        };

        const handleCancel = () => {
            resolve(false);
            closeModal();
        };

        const closeModal = () => {
            container.unmount();
        };

        const container = ReactDOM.createRoot(document.createElement('div'));

        container.render(
            <VbModal
                show={true}
                title="Confirmação"
                onHide={handleCancel}
                success={() => handleConfirm()}
                cancel={() => handleCancel()}
            >
                {message}
            </VbModal>
        );
    });
}

