import { useRef } from "react"
import { isMobileDevice } from "../../../commons/utils";
import { Grid } from "@mui/material";
import { ProspectChart } from "../ProspectChart";
import { FiltroConvertidosProfessor } from "../../Commons/FiltroConvertidosProfessor";

export const DashboardConvertidosProfessor = () => {
    const refChart = useRef<any>();
    const refFiltro = useRef<any>();

    function onGetData(data: any) {
        const listProfessor: any = {};

        for (const item of data) {
            const professor = item.usunome;

            if (professor in listProfessor)
                listProfessor[professor]++
            else
                listProfessor[professor] = 1;
        }

        const object_professor: any = Object.keys(listProfessor);
        refChart?.current.setData(data);
        refChart?.current.setLabels(object_professor);
        refChart?.current.setSeries(object_professor.map((professor: any) => listProfessor[professor]));
    };


    function filterUsuID(data: any, value: any) {
        const filteredData = data.filter((item: any) => item.usunome === value);
        return filteredData.map((item: any) => item.prospect);
    }

    return (
        <Grid container spacing={2} sx={{ padding: isMobileDevice() ? '1rem' : '2rem 8rem' }}>
            <FiltroConvertidosProfessor ref={refFiltro} onGetData={onGetData} style={{ paddingLeft: '16px' }} />
            <ProspectChart
                ref={refChart}
                title={'PROSPECTS CONVERTIDOS POR PROFESSOR'}
                xs={12}
                filter={filterUsuID}
            />
        </Grid >
    )
}