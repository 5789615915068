import { Col, Form, InputGroup } from "react-bootstrap"
import styled from "styled-components"
import { VbFormLabel } from "../VbControls"
import { forwardRef, useImperativeHandle, useState } from "react";
import { COLOR_PRIMARY } from "../../theme/styles";

const Container = styled.div`
`

const Button = styled.button`
    font-size: 13px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    margin-right: 5px;
    user-select: none;
`

interface WeekInputProps {
    id: any;
    label?: String;
    required?: any;
    md?: String;
    ref?: any;
    size?: any;
    defaultValue?: any;
}

export const WeekInput: React.FC<WeekInputProps> = forwardRef((props: WeekInputProps, ref: any) => {
    const arr_days = [
        { label: 'Dom', value: 1 },
        { label: 'Seg', value: 2 },
        { label: 'Ter', value: 4 },
        { label: 'Qua', value: 8 },
        { label: 'Qui', value: 16 },
        { label: 'Sex', value: 32 },
        { label: 'Sáb', value: 64 }];

    const [value, setValue] = useState<any>(props.defaultValue ? props.defaultValue : 0);

    function getValue() {
        return value
    }

    function getId() {
        return props.id
    }

    function validate() {
        const result = props.required ? (value !== 0) : true;
        return result
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        getId,
        validate
    }));


    return (
        <Form.Group as={Col} md={props?.md ? props.md : "12"} controlId={props.id}>
            {props.label && <VbFormLabel>{(props?.required ? '*' : '') + props.label}</VbFormLabel>}
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                <Container>
                    {arr_days.map((day: any, index: any) => {
                        return (
                            <Button
                                key={index}
                                type="button"
                                onClick={() => { setValue(value + ((day.value & value) !== 0 ? (-day.value) : day.value)) }}
                                style={{ backgroundColor: (day.value & value) !== 0 ? COLOR_PRIMARY : '#a2a2a2' }}>
                                {day.label}
                            </Button>
                        )
                    })}
                </Container>
            </InputGroup>
        </Form.Group>
    )
})