import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_especificacao } from "../../../../models";

export const Especificacao = () => {
    const columns = [
        { field: 'especid', header: 'Código' },
        { field: 'especdescricao', header: 'Descrição' },
        { field: 'exercicio.exercdescricao', header: 'Exercício' },
        { field: 'especativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];
    

    return (
        <ConsultaPadrao model={model_especificacao} columns={columns} />
    )
}