import { forwardRef, useRef, useState } from "react";
import DetailsChart from "../../../charts/Details";
import styled from "styled-components";
import PieChart from "../../../charts/Pie";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { columns_prospect, details_prospect } from "../../Cadastros/Pessoas/Prospect";
import { model_prospect } from "../../../models";
import { MdClose } from "react-icons/md";

const Card = styled.div`
    background-color: rgba(255, 255, 255, .05);
    border-radius: 10px;
    box-shadow: 2px 2px 1px 0px rgba(255, 255, 255, .20);
    position: relative;  
    max-height: 500px;
`

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;

    &:hover{
        background-color: rgba(255, 255, 255, 0.123);
    }
`

const Title = styled.h6`
    text-align: center;
    padding-top: 20px;
`

export const ProspectChart = forwardRef((props: any, ref: any) => {
    const detailsRef = useRef<any>();
    const [data, setData] = useState<any>();

    async function cellClick(data: any, cellValue: any) {
        setData(undefined);

        try {
            const response = await props.filter(data, cellValue);
            setData(response)
        } catch (error) {
            console.error('Erro ao filtrar os dados:', error);
        }

        detailsRef?.current.setFiltered(true);
    }

    return (
        <DetailsChart
            ref={detailsRef}
            xs={props.xs}
            detail={
                data &&
                <ConsultaPadrao
                    model={model_prospect}
                    columns={columns_prospect}
                    details={details_prospect}
                    prevdata={data}
                />
            }
        >
            <Card>
                {data && <CloseButton onClick={() => { setData(undefined); detailsRef?.current.setFiltered(false) }}><MdClose /></CloseButton>}
                <Title>{props?.title}</Title>
                <PieChart
                    ref={ref}
                    legendPosition={'top'}
                    cellClick={(data: any, cellValue: string) => cellClick(data, cellValue)}
                />
            </Card>

        </DetailsChart>
    )
});
