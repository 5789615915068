import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_motivo } from "../../../../models";

export const Motivo = () => {
    const columns = [
        { field: 'motid', header: 'Código' },
        { field: 'motdescricao', header: 'Descrição' },
        { field: 'motativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_motivo} columns={columns} />
    )
}