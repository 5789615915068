import { isMobileDevice } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_metodo } from "../../../../models";

export const Metodo = () => {
    function drawColors(e: any) {
        return (
            <div style={{
                backgroundColor: e.metodocor,
                maxWidth: isMobileDevice() ? '100%' : '10px',
                width: '100%',
                height: isMobileDevice() ? '3px' : '30px',
                borderRadius: '2px'
            }} />
        )
    }

    const columns = [
        { field: 'metodocor', header: '', notSortable: true, body: (e: any) => drawColors(e), style: { width: '2rem' } },
        { field: 'metodoid', header: 'Código' },
        { field: 'metodotitulo', header: 'Título' },
        { field: 'metododescricao', header: 'Descrição' },
        { field: 'metodoativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_metodo} columns={columns} />
    )
}