import { formatDataHoraSemSegundo, formatMonetary } from "../../../commons/utils";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { LabelDataTable } from "../../../components/VbDataTable";
import { model_docao } from "../../../models";
import { COLOR_GREEN, COLOR_RED, COLOR_ORANGE, COLOR_BLUE, COLOR_PURPLE } from "../../../theme/styles";

export const Doacao = () => {
    const labelSituacao = (situacao: any) => {
        return (
            <LabelDataTable color={situacao === 0 ? COLOR_ORANGE : situacao === 1 ? COLOR_GREEN : COLOR_RED}>
                {situacao === 0 ? 'ABERTO' : situacao === 1 ? 'PAGO' : 'CANCELADO'}
            </LabelDataTable >
        )
    }

    const labelTipo = (tipo: any) => {
        return (
            <LabelDataTable color={tipo.toUpperCase() === 'AVULSO' ? COLOR_BLUE : COLOR_PURPLE}>
                {tipo.toUpperCase()}
            </LabelDataTable >
        )
    }


    const columns = [
        { field: 'doacaoid', header: 'Código' },
        { field: 'doacaodatahora', header: 'Data', body: (e: any) => formatDataHoraSemSegundo(e.doacaodatahora) },
        { field: 'doacaonome', header: 'Nome', autoWidth: true },
        { field: 'doacaoemail', header: 'E-mail', autoWidth: true },
        { field: 'doacaowhatsapp', header: 'WhatsApp' },
        { field: 'doacaotipo', hedaer: 'Tipo', body: (e: any) => labelTipo(e.doacaotipo), style: { width: '135px' } },
        { field: 'doacaovalor', header: 'Valor', body: (e: any) => formatMonetary(e.doacaovalor) },
        { field: 'totalparcial', header: 'Total Parcial', body: (e: any) => formatMonetary(e.totalparcial) },
        { field: 'doacaosituacao', header: 'Situação', body: (e: any) => labelSituacao(e.doacaosituacao), style: { width: '135px' } }
    ];

    return (
        <ConsultaPadrao model={model_docao} columns={columns} sortfield={'doacaoid'} sortorder={-1} />
    )
}