import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_modalidade } from "../../../../models";

export const Modalidade = () => {
    const columns = [
        { field: 'modalidid', header: 'Código' },
        { field: 'modaliddescricao', header: 'Descrição' },
        { field: 'modalidativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_modalidade} columns={columns} />
    )
}