import { useEffect, useState } from "react";
import { VbNotification } from "./components/VbNotification";
import { useAuth } from "./contexts/AuthProvider/useAuth";
import { Login } from "./pages/Login";
import { Principal } from "./pages/Principal";
import { useLocation } from "react-router-dom";
import { ProspectPub } from "./pages/Public/Prospect";
import { AnamensePub } from "./pages/Public/Anamnese";
import { SuccessPub } from "./pages/Public/Success";

function App() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (auth.usunome !== null) {
      setLoading(false);
    }
  }, [auth.usunome]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {location.pathname.startsWith("/prospect-form") ? <ProspectPub /> :
        location.pathname.startsWith("/anamnese-form") ? <AnamensePub /> :
          location.pathname.startsWith("/success-form") ? <SuccessPub /> :
            !auth.usunome ? <Login /> : <Principal />
      }
      < VbNotification />
    </div>
  );
}

export default App;
