import { useNavigate } from "react-router-dom";
import { Login } from "../../pages/Login";
import { useAuth } from "./useAuth";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth();
    const navigate = useNavigate();

    if (!auth.usunome) {
        navigate('/')
        return <Login />
    }

    return children;
}