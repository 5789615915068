import { Card, Container, Form, Row } from "react-bootstrap"
import { VbInput } from "../../components/VbInput"
import { useRef, useState } from "react";
import { VbButton } from "../../components/VbButton";
import styled from "styled-components";
import LogoPequena from "../../Images/LogoPequena.png"
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import LoginIcon from '@mui/icons-material/Login';

const LoginContainer = styled(Container)`
    border-right: 1px solid #c2c2c2;
    height: 100vh;
    width: 25%;
    min-width: 25rem;
`
const Logo = styled.img`
    width: 100%;
    padding: 0 4rem;
`

export const Login = () => {
    const auth = useAuth();

    const usernameRef = useRef<any>();
    const passwordRef = useRef<any>();

    const [validated, setValidated] = useState(false);

    async function autenticate() {
        var username = usernameRef.current.getValue();
        var password = passwordRef.current.getValue();

        try {
            await auth.authenticate(username, password);
        } catch (error: any) {
            setValidated(false);
            usernameRef.current.setError(true);
            passwordRef.current.setError(true);

            var msg = error.response.data.mensagem ? error.response.data.mensagem : 'Não foi possível realizar a autenticação no momento.';

            toast.error(msg, { toastId: msg });
        }
    }

    const handleSubmit = (e: any) => {
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            autenticate()
        else
            setValidated(true);

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <LoginContainer className="p-4 m-0" >
            <Form noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
                <Row className="mb-5 pt-4 justify-content-center">
                    <Logo src={LogoPequena} />
                </Row>
                <Row className="justify-content-center">
                    <Card className="border-0 p-0">
                        <Card.Body>
                            <Card.Title>Acesso ao Sistema</Card.Title>
                            <Card.Text className="mb-4">Insira suas credenciais para acessar a plataforma</Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className="mb-2 justify-content-center">
                    <VbInput ref={usernameRef} id="email" type="text" label="E-mail" required />
                </Row>
                <Row className="mb-4 justify-content-center">
                    <VbInput ref={passwordRef} id="senha" type="password" label="Senha" required />
                </Row>
                <Row className="mb-2 justify-content-end m-0">
                    <VbButton type="submit" icon={<LoginIcon />} style={{ width: '100px' }} >Acessar</VbButton>
                </Row>
            </Form>
        </LoginContainer>
    )
}