import { useSortable } from "@dnd-kit/sortable";
import styled from "styled-components";
import { Id, Task } from "./Kanban";
import { Col, Row } from "react-bootstrap";

const Card = styled.div`
    display: flex;
    height: auto;
    background-color: #EAF4FD;
    color: #2b2b2b;
    font-weight: 500;
    align-items: center;
    border-radius: 6px;  
    padding: 10px;
    padding-left: 15px;
    user-select: none;
    z-index: 999;
    cursor: pointer;
`

const CardPaste = styled(Card)`
    min-height: 50px;
    max-height: 50px;
`

const Description = styled.div`
    text-align: left;
    font-size: 14px;
`

interface Props {
    task: Task;
    color: any;
    deleteTask: (id: Id) => void;
    updateTask: (id: Id, content: string) => void;
}

function TaskCard({ task, color, deleteTask, updateTask }: Props) {
    const { setNodeRef, isDragging } =
        useSortable({
            id: task.id,
            data: {
                type: "Task",
                task,
            },
        });

    if (isDragging) {
        return (
            <CardPaste ref={setNodeRef} style={{ border: '2px solid ' + color }} />
        );
    }

    return (
        <Card ref={setNodeRef} style={{ borderLeft: '6px solid ' + color }}>
            <Col>
                <Row>
                    <Description style={{ fontSize: '11px', marginTop: '-3px' }}>{task.details}</Description>
                </Row>
                <Row>
                    <Description>{task.content}</Description>
                </Row>
                <Row>
                    <Description style={{ fontSize: '11px', marginTop: '-3px' }}>{task.infoAdditional}</Description>
                </Row>
                <Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                        {task.functions}
                    </div>
                </Row>
            </Col>
        </Card>
    );
}

export default TaskCard;