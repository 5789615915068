import KanbanBoard, { Column } from "../../../components/VbKanban/Kanban";
import { FaPersonCane } from "react-icons/fa6";
import { TbMessage2Check } from "react-icons/tb";
import { LuCalendarDays } from "react-icons/lu";
import { GiWeightLiftingUp } from "react-icons/gi";
import { FaRegSadTear } from "react-icons/fa";
import { useRef } from "react";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { model_agenda, model_prospect_emespera, model_prospect_naoconvertido } from "../../../models";
import { toast } from "react-toastify";
import { LuFileCheck } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa6";
import { LuFileSymlink } from "react-icons/lu";
import { GenerateAnamnese } from "../../Cadastros/Pessoas/Prospect/Anamnese";
import { ButtonCard, sendMessageBitSafira } from "../../../commons/utils";
import { CadastroPadrao } from "../../../components/CadastroPadrao";
import { FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineDislike } from "react-icons/ai";
import { FaRegGrinStars } from "react-icons/fa";
import { AiOutlineLike } from "react-icons/ai";
import { LuClock } from "react-icons/lu";
import { LuHistory } from "react-icons/lu";
import { FiltroProspect } from "../../Commons/FiltroProspect";
import { PDFViewer } from "../../../components/PDFViewer";

export const prospect_status: Column[] = [
    { id: "prospect", title: "PROSPECT", color: '#E76135', icon: <FaPersonCane />, value: 1 },
    { id: "anamnese", title: "ANAMNESE RESPONDIDA", color: '#3f8684', icon: <TbMessage2Check />, value: 2 },
    { id: "agendada", title: "AULA AGENDADA", color: '#8b40d5', icon: <LuCalendarDays />, value: 3 },
    { id: "pos", title: "PÓS EXPERIMENTAL", color: '#388ABA', icon: <GiWeightLiftingUp />, value: 4 },
    { id: "espera", title: "EM ESPERA", color: '#8f7414', icon: <LuClock />, value: -1 },
    { id: 'convertido', title: 'CONVERTIDO', color: '#126f29', icon: <FaRegGrinStars />, value: 5 },
    { id: "naoconvertido", title: "NÃO CONVERTIDO", color: '#bf3636', icon: <FaRegSadTear />, value: 999 }
];

function getSituacaoDescription(situacao: any) {
    const situacoes: { [key: string]: string } = {
        "-1": "espera",
        "1": "prospect",
        "2": "anamnese",
        "3": "agendada",
        "4": "pos",
        "5": "convertido"
    };

    return situacoes[situacao] || "naoconvertido";
}

export const ControleProspeccao = () => {
    const auth = useAuth();

    const refAgenda = useRef<any>();
    const refNaoConvertido = useRef<any>();
    const refKanbam = useRef<any>();
    const refEmEspera = useRef<any>();
    const refFiltro = useRef<any>();
    const refAnamnese = useRef<any>();

    async function updateStatus(empid: any, prospid: any, contprospid: any, situacao: any) {
        await auth.post('/prospect/alterarSituacao', {
            empid,
            prospid,
            contprospid,
            contprospsituacao: situacao
        }).then(() => {
            toast.success('Registro atualizado.')
        }).catch((error: any) => {
            console.log(error)
        })
    }

    async function onClickGenerateAnamnese(item: any) {
        const anamnese = await GenerateAnamnese(item, item.anamnese);
        refAnamnese.current.openModal(anamnese);
    }

    function onGetData(data: any) {
        const newArray: any = [];
        refKanbam.current.setTasks([]);

        data.forEach((item: any) => {
            const { empid, empnome } = item.empresa;
            const { prospid, prospnome, prosptoken, prosptelefone } = item;
            const { contprospsituacao, motivo, contprospobservacao, contprospid } = item.controleProspeccao;
            const { modalidid, modaliddescricao } = item.modalidade;

            const id = `${empid} - ${prospid} - ${modalidid}`;
            const content = `${prospid} - ${prospnome}`;
            const columnId = getSituacaoDescription(contprospsituacao);
            const details = empnome;

            const infoAdditional = (
                <div style={{ marginTop: '3px', marginBottom: '3px' }}>
                    <div>{modaliddescricao && modaliddescricao}</div>
                    {contprospsituacao === 999 && (
                        <>
                            <div>{`MOTIVO: ${motivo?.motdescricao}`}</div>
                            {contprospobservacao && <div>{`OBS: ${contprospobservacao}`}</div>}
                        </>
                    )}
                    {contprospsituacao === -1 && `OBS: ${contprospobservacao}`}
                </div>
            );

            const createButton = (hint: any, color: any, icon: any, onClick: any) => (
                <ButtonCard hint={hint} color={color} iconcenter={icon} onClick={onClick} />
            );

            const color = prospect_status.find(info => info.value === contprospsituacao)?.color;

            const functions = (
                <>
                    {contprospsituacao === -1 && createButton(
                        'Retornar para "Anamnese Respondida"', color, <LuHistory style={{ fontSize: '16px' }} />,
                        () => updateStatus(empid, prospid, contprospid, 2).then(() => refFiltro.current.refresh())
                    )}

                    {contprospsituacao === 1 && createButton(
                        'Enviar Anamnese', color, <LuFileSymlink style={{ fontSize: '16px' }} />,
                        () => sendMessageBitSafira(prosptelefone, prospnome, prosptoken, auth)
                    )}

                    {contprospsituacao === 2 && createButton(
                        'Agendar Aula', color, <FaRegCalendarAlt style={{ fontSize: '16px' }} />,
                        () => refAgenda.current.openModal({ ...model_agenda.base, prospect: { prospid, empresa: { empid } } })
                    )}

                    {contprospsituacao === 3 && createButton(
                        'Baixar Anamnese Respondida', color, <LuFileCheck style={{ fontSize: '16px' }} />,
                        () => onClickGenerateAnamnese(item)
                    )}

                    {contprospsituacao === 4 && createButton(
                        'Nova Agenda', color, <FaRegCalendarAlt style={{ fontSize: '16px' }} />,
                        () => refAgenda.current.openModal({ ...model_agenda.base, prospect: { prospid, empresa: { empid } } })
                    )}

                    {(contprospsituacao === 4 || contprospsituacao === -1) && createButton(
                        'Converter', color, <AiOutlineLike style={{ fontSize: '16px' }} />,
                        () => updateStatus(empid, prospid, contprospid, 5).then(() => refFiltro.current.refresh())
                    )}

                    {contprospsituacao !== 999 && createButton(
                        'Não Convertido', color, <AiOutlineDislike style={{ fontSize: '16px' }} />,
                        () => refNaoConvertido.current.openModal({
                            ...model_prospect_naoconvertido.base, prospid, contprospid, empid
                        })
                    )}

                    {createButton(
                        'Abrir WhatsApp Web', color, <FaWhatsapp style={{ fontSize: '16px' }} />,
                        () => window.open(`https://wa.me/+55${prosptelefone.replace(/\D/g, "")}`, '_blank')
                    )}

                    {(contprospsituacao > 1 && contprospsituacao !== 5 && contprospsituacao !== 999) && createButton(
                        'Em Espera', color, <LuClock style={{ fontSize: '16px' }} />,
                        () => refEmEspera.current.openModal({
                            ...model_prospect_emespera.base, prospid, contprospid, empid
                        })
                    )}
                </>
            );

            newArray.push({ id, content, columnId, details, infoAdditional, functions });
        });

        refKanbam.current.setTasks(newArray);
    }

    return (
        <>
            <FiltroProspect ref={refFiltro} onGetData={onGetData} style={{ padding: '0px 20px' }} />
            <KanbanBoard ref={refKanbam} columnsId={prospect_status.map(item => item.id)} updateStatus={updateStatus} status={prospect_status} />
            <CadastroPadrao ref={refAgenda} model={model_agenda} success={() => refFiltro.current.refresh()} />
            <CadastroPadrao ref={refNaoConvertido} model={model_prospect_naoconvertido} success={() => refFiltro.current.refresh()} />
            <CadastroPadrao ref={refEmEspera} model={model_prospect_emespera} success={() => refFiltro.current.refresh()} />
            <PDFViewer ref={refAnamnese} />
        </>
    );
}