import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_local } from "../../../../models";

export const Local = () => {
    const columns = [
        { field: 'localid', header: 'Código' },
        { field: 'localdescricao', header: 'Descrição' },
        { field: 'localativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_local} columns={columns} />
    )
}