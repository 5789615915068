import axios from 'axios';

const currentUrl = window.location.href;

export function getApi() {
    return (currentUrl.includes('localhost') ||
        currentUrl.includes('crm-hom.vbfit.com.br') ||
        currentUrl.includes('127.0.0.1') ||
        currentUrl.includes('25.1.177.182') ||
        currentUrl.includes('192.168.0.107')) ?
        '-hom' : ''
}

export const Api = axios.create({
    baseURL: 'https://api' + getApi() + '.vbfit.com.br/'
})
