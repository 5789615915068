import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { forwardRef, useImperativeHandle, useState } from "react";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const Container = styled.div`
    align-items: center;
    background-color: #eeeeee;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    padding: 4px;
`

export const PDFViewer = forwardRef((props: any, ref) => {
    const [pdfBlob, setPdfBlob] = useState(null);
    const [show, setShow] = useState(false);

    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;

    const getFilePluginInstance = getFilePlugin();
    const { DownloadButton } = getFilePluginInstance;

    async function openModal(blob: any) {
        setPdfBlob(blob);
        setShow(true);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    return (
        <Modal show={show} size="xl" onHide={() => setShow(false)}>
            <Container>
                <ZoomOutButton />
                <ZoomPopover />
                <ZoomInButton />
                <DownloadButton />
            </Container>
            {pdfBlob &&
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={URL.createObjectURL(pdfBlob)} plugins={[zoomPluginInstance, getFilePluginInstance]} />
                </Worker>
            }
        </Modal>
    );
});