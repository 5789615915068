import ReactApexChart from 'react-apexcharts';
import React from 'react';
import ReactLoading from 'react-loading';
import { COLOR_PRIMARY } from '../../theme/styles';

export interface IProps {
    legendPosition?: any;
    removeLegend?: any;
    dataOffset?: any,
    height?: any;
    cellClick?: any;
    colors?: any;
}

interface IState {
    options?: any;
    series?: any;
    height?: any;
    data?: any;
    loading?: any;
}

class PieChart extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            series: [],
            data: [],
            height: props.height,
            loading: true,
            options: {
                labels: [],
                theme: !props.colors ? {
                    monochrome: {
                        enabled: true,
                        color: '#ff5500',
                        shadeTo: 'dark',
                        shadeIntensity: .9
                    }
                } : {},
                colors: props.colors,
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: (props.dataOffset !== undefined) ? props.dataOffset : - 5,
                            minAngleToShowLabel: 12
                        }
                    }
                },
                dataLabels: {
                    formatter(val: any, opts: any) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        const value = opts.w.globals.series[opts.seriesIndex]
                        return [name, val.toFixed(1) + '% (' + value + ')']
                    }
                },
                legend: {
                    fontColor: '#fff',
                    position: props.legendPosition ? props.legendPosition : 'right',
                    show: props.removeLegend ? false : true,
                },
                stroke: {
                    width: 0.65
                },
                chart: {
                    events: {
                        dataPointSelection: (event: any, chartContext: any, config: any) => {
                            if (props.cellClick)
                                props.cellClick(this.state.data, config.w.globals.labels[config.dataPointIndex]);
                        }
                    }
                },
                noData: {
                    text: 'NENHUM REGISTRO ENCONTRADO',
                    style: { color: '#fff' }
                }
            }
        }
    }


    setSeries = (series: any[] | []) => {
        this.setState({
            series,
            loading: false
        });
    }

    setData = (data: any[] | []) => {
        this.setState({
            data
        });
    }


    setLabels = (labels: any[] | []) => {
        this.setState({
            options: {
                ...this.state.options,
                labels,
            }
        });
    }

    render() {
        return (
            this.state.loading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReactLoading type={'spinningBubbles'} color={COLOR_PRIMARY} height={150} width={100} />
                </div> :
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    height={this.state.height ? this.state.height : '450px'}
                    type="pie"
                    align="center"
                />

        )
    }
}

export default PieChart;