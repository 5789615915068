import { Api } from "../../api/address";
import { IUser } from "./types";

export function setUserLocalStorage(user: IUser | null) {
    localStorage.setItem('vbfit_user', JSON.stringify(user));
}

export function getUserLocalStorage() {
    const json = localStorage.getItem('vbfit_user')

    if (!json) {
        return null;
    }

    const user = JSON.parse(json)

    return user ?? null;
}

export async function LoginRequest(username: string, password: string) {
    try {
        const request = await Api.get('usuario/autenticar', {
            auth: {
                username: username,
                password: password
            }
        })

        return request.data;
    } catch (error: any) {
        return error
    }
}

export async function GetRequest(url: string, token: any) {
    const params: any = new URLSearchParams(window.location.search);

    const config = {
        headers: {
            'Token': params.get("u_vbfit") ? params.get("u_vbfit") : token
        }
    }

    try {
        const response = await Api.get(url, config);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function PostRequest(url: string, token: any, data: any) {
    const params: any = new URLSearchParams(window.location.search);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': params.get("u_vbfit") ? params.get("u_vbfit") : token
        }
    }

    try {
        const response = await Api.post(url, data, config);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function DeleteRequest(url: string, token: any, data: any) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': token
        },
        data: data
    }

    try {
        const response = await Api.delete(url, config);
        return response;
    } catch (error: any) {
        return error
    }
}