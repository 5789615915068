import { formatData, intToDiasSemana, intToTipoAula } from "../../../../commons/utils";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_aula } from "../../../../models";

export const Aula = () => {
    const columns = [
        { field: 'aulaid', header: 'Código' },
        { field: 'turma.turmadescricao', header: 'Turma' },
        { field: 'aulainicio', header: 'Hora Inicial' },
        { field: 'aulafim', header: 'Hora Final' },
        { field: 'auladatainicio', header: 'Data Início', body: (e: any) => formatData(e.auladatainicio) },
        { field: 'aulatipo', header: 'Tipo', body: (e: any) => intToTipoAula(e.aulatipo) },
        { field: 'auladias', header: 'Dias', body: (e: any) => intToDiasSemana(e.auladias) },
        { field: 'aulaativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_aula} columns={columns} sortfield={'aulainicio'} />
    )
}