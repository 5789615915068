import { FaLocationDot, FaRegNewspaper } from "react-icons/fa6";
import { MdFitnessCenter } from "react-icons/md";
import { RiUserSearchLine } from "react-icons/ri";
import { FaIdCardClip } from "react-icons/fa6";
import { FaUnlockAlt } from "react-icons/fa";
import { FaUserLock } from "react-icons/fa";
import { getApiCEP } from "../commons/utils";
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import Groups2Icon from '@mui/icons-material/Groups2';
import { MdSupportAgent } from "react-icons/md";
import { TfiAgenda } from "react-icons/tfi";
import { BiMessageEdit } from "react-icons/bi";
import { BsQuestionOctagonFill } from "react-icons/bs";
import { LuAlertTriangle } from "react-icons/lu";
import { FaPersonRunning } from "react-icons/fa6";
import { GiJumpingRope } from "react-icons/gi";
import { TbHistoryToggle } from "react-icons/tb";

const options_sexo = [
    { value: 0, label: 'MASCULINO' },
    { value: 1, label: 'FEMININO' },
    { value: 2, label: 'PREFIRO NÃO INFORMAR' }
]

const options_tipo_aula = [
    { value: 1, label: 'DIÁRIO' },
    { value: 2, label: 'SEMANAL' }
]

export function getSexo(_value: any) {
    const sexo = options_sexo.find(option => option.value === _value);
    return sexo ? sexo.label : 'Opção não encontrada';
}

export const model_local = {
    icon: <FaLocationDot />,
    name: 'Local',
    key: 'localid',
    inactive_field: 'localativo',
    description_field: 'localdescricao',
    api_get: '/locais',
    api_post: '/local',
    api_delete: '/local',
    inputs: [
        { id: 'localid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'localdescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 100 }
    ],
    base: {
        localid: 0,
        localdescricao: '',
        localativo: 1
    }
}

export const model_modalidade = {
    icon: <MdFitnessCenter />,
    name: 'Modalidade',
    key: 'modalidid',
    inactive_field: 'modalidativo',
    description_field: 'modaliddescricao',
    api_get: '/modalidades',
    api_post: '/modalidade',
    api_delete: '/modalidade',
    inputs: [
        { id: 'modalidid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'modaliddescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 100 }
    ],
    base: {
        modalidid: 0,
        modaliddescricao: '',
        modalidativo: 1
    }
}

export const model_tipo_prospeccao = {
    icon: <RiUserSearchLine />,
    name: 'Tipo Prospecção',
    key: 'tipprospecid',
    inactive_field: 'tipprospecativo',
    description_field: 'tipprospecdescricao',
    api_get: '/tiposProspeccao',
    api_post: '/tipoProspeccao',
    api_delete: '/tipoProspeccao',
    inputs: [
        { id: 'tipprospecid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'tipprospecdescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 100 },
        { id: 'tipprospecindicaluno', label: 'Indicação Aluno', type: 'check', md: 6 },
        { id: 'tipprospecindicterceiro', label: 'Indicação Terceiro', type: 'check', md: 6 },
    ],
    base: {
        tipprospecid: 0,
        tipprospecdescricao: '',
        tipprospecindicaluno: 0,
        tipprospecindicterceiro: 0,
        tipprospecativo: 1
    }
}

export const model_cores = {
    key: 'corid',
    description_field: 'cordescricao',
    api_get: '/turma/cores'
}

export const model_turma = {
    icon: <Groups2Icon />,
    name: 'Turma',
    key: 'turmaid',
    inactive_field: 'turmaativo',
    description_field: 'turmadescricao',
    api_get: '/turmas',
    api_post: '/turma',
    api_delete: '/turma',
    inputs: [
        { id: 'turmaid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'turmadescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 100 },
        { id: 'local.localid', label: 'Local', type: 'select', md: 12, required: true, model: model_local },
        { id: 'modalidade.modalidid', label: 'Modalidade', type: 'select', md: 12, required: true, model: model_modalidade },
        { id: 'turmatempo', label: 'Tempo (Minutos)', type: 'number', md: 4, required: true },
        { id: 'turmavagas', label: 'Vagas', type: 'number', md: 4, required: true },
        { id: 'turmacor', label: 'Cor', type: 'select', md: 4, required: true, model: model_cores },
    ],
    base: {
        turmaid: 0,
        turmadescricao: '',
        turmavagas: 0,
        turmatempo: 0,
        turmacor: 0,
        turmaativo: 1,
        local: { localid: 0 },
        modalidade: { modalidid: 0 }
    }
}

export const model_privilegio = {
    icon: <FaUnlockAlt />,
    name: 'Privilégio',
    key: 'privid',
    description_field: 'privdescricao',
    api_get: '/privilegios',
    api_post: '/privilegio',
    api_delete: '/privilegio',
    inputs: [
        { id: 'privid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'privdescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 30 }
    ],
    base: {
        privid: 0,
        privdescricao: ''
    }
}

export const model_usuario = {
    icon: <FaIdCardClip />,
    name: 'Usuário',
    key: 'usuid',
    description_field: 'usunome',
    api_get: '/usuarios',
    api_post: '/usuario',
    api_delete: '/usuario',
    inputs: [
        { id: 'usuid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'usunome', label: 'Nome', type: 'text', md: 9, required: true, maxLength: 50 },
        { id: 'usuemail', label: 'E-mail', type: 'text', md: 12, required: true, maxLength: 40 },
        { id: 'ususenha', label: 'Senha', type: 'password', md: 12, required: true, maxLength: 100 }
    ],
    base: {
        usuid: 0,
        usunome: '',
        usuemail: '',
        ususenha: ''
    }
}

export const model_usuario_empresa = {
    icon: <FaUserLock />,
    name: 'Usuário Empresa',
    key: 'usuid',
    inactive_field: 'usuempativo',
    description_field: 'usuario.usunome',
    api_get: '/usuariosEmpresa',
    api_post: '/usuarioEmpresa',
    inputs: [
        { id: 'usuario.usuid', label: 'Usuário', type: 'select', md: 12, model: model_usuario },
        { id: 'privilegio.privid', label: 'Privilégio', type: 'select', md: 12, required: true, model: model_privilegio },
    ],
    base: {
        usuario: {
            usuid: 0
        },
        privilegio: {
            privid: 0
        },
        usuempativo: 1
    }
}

function changeCEP(_newValue: any, refs: any) {
    if (_newValue.length === 9) {
        getApiCEP(_newValue.replace('-', ''))
            .then((data: any) => {
                refs.current['prosplogradouro'].setValue(data.logradouro);
                refs.current['prospbairro'].setValue(data.bairro);
                refs.current['prospcidade'].setValue(data.localidade);
                refs.current['prospcomplemento'].setValue(data.complemento);
                refs.current['propsuf'].setValue(data.uf);
            })
            .catch((error: any) => {
                console.log(error)
            })
    }
}

export const model_prospect = {
    icon: <EscalatorWarningIcon />,
    name: 'Prospect',
    key: 'prospid',
    api_get: '/prospects',
    api_post: '/prospect',
    api_delete: '/prospect',
    hasEmpresa: true,
    description_field: 'prospnome',
    inputs: [
        { id: 'prospid', label: 'Código', type: 'number', disabled: true, md: 2 },
        { id: 'prospnome', label: 'Nome', type: 'text', md: 10, required: true, maxLength: 50 },
        { id: 'prospcpf', label: 'CPF', type: 'text', md: 4, required: true, model: 'cpf' },
        { id: 'prosptelefone', label: 'Telefone', type: 'text', md: 4, required: true, model: 'phone' },
        { id: 'prosptelemergencia', label: 'Tel. Emergência', type: 'text', md: 4, required: true, model: 'phone' },
        { id: 'prospemail', label: 'E-mail', type: 'text', md: 6, required: true, model: 'email', maxLength: 50 },
        { id: 'prospnascimento', label: 'Nascimento', type: 'date', md: 3, required: true },
        { id: 'prospsexo', label: 'Sexo', type: 'select', md: 3, required: true, options: options_sexo },
        { id: 'tipoProspeccao.tipprospecid', label: 'Tipo Prospecção', type: 'select', md: 5, required: true, model: model_tipo_prospeccao },
        { id: 'usuario.usuid', label: 'Consultor', type: 'select', md: 4, required: true, model: model_usuario },
        { id: 'prospcep', label: 'CEP', type: 'text', md: 3, required: true, model: 'cep', onAfterChange: changeCEP },
        { id: 'prosplogradouro', label: 'Endereço', type: 'text', md: 10, required: true, maxLength: 50 },
        { id: 'prospnumero', label: 'Número', type: 'text', md: 2, required: true, maxLength: 10 },
        { id: 'prospbairro', label: 'Bairro', type: 'text', md: 4, required: true, maxLength: 30 },
        { id: 'prospcidade', label: 'Cidade', type: 'text', md: 3, required: true, maxLength: 30 },
        { id: 'prospuf', label: 'UF', type: 'text', md: 2, required: true, maxLength: 2 },
        { id: 'prospcomplemento', label: 'Complemento', type: 'text', md: 3, maxLength: 50 },
    ],
    inputs_pub: [
        { id: 'prospnome', label: 'Nome', type: 'text', md: 12, required: true, maxLength: 50 },
        { id: 'prospcpf', label: 'CPF', type: 'text', md: 4, required: true, model: 'cpf' },
        { id: 'prosptelefone', label: 'Telefone', type: 'text', md: 4, required: true, model: 'phone' },
        { id: 'prosptelemergencia', label: 'Tel. Emergência', type: 'text', md: 4, required: true, model: 'phone' },
        { id: 'prospemail', label: 'E-mail', type: 'text', md: 8, required: true, model: 'email', maxLength: 50 },
        { id: 'prospnascimento', label: 'Nascimento', type: 'date', md: 4, required: true },
        { id: 'prospsexo', label: 'Sexo', type: 'select', md: 6, required: true, options: options_sexo },
        { id: 'tipoProspeccao.tipprospecid', label: 'Tipo Prospecção', type: 'select', md: 6, required: true, model: model_tipo_prospeccao },
        { id: 'prospcep', label: 'CEP', type: 'text', md: 3, required: true, model: 'cep', onAfterChange: changeCEP },
        { id: 'prosplogradouro', label: 'Endereço', type: 'text', md: 9, required: true, maxLength: 50 },
        { id: 'prospnumero', label: 'Número', type: 'text', md: 3, required: true, maxLength: 10 },
        { id: 'prospbairro', label: 'Bairro', type: 'text', md: 9, required: true, maxLength: 30 },
        { id: 'prospcidade', label: 'Cidade', type: 'text', md: 9, required: true, maxLength: 30 },
        { id: 'prospuf', label: 'UF', type: 'text', md: 3, required: true, maxLength: 2 },
        { id: 'prospcomplemento', label: 'Complemento', type: 'text', md: 12, maxLength: 50 },
    ],
    base: {
        prospid: 0,
        prospnome: '',
        prospcpf: '',
        prosptelefone: '',
        prospemail: '',
        prospnascimento: '',
        prospsexo: 0,
        prospcep: '',
        prosplogradouro: '',
        prospnumero: '',
        prospbairro: '',
        prospcidade: '',
        prospcomplemento: '',
        prosptelemergencia: '',
        tipoProspeccao: {
            tipprospecid: 0
        },
        usuario: {
            usuid: 0
        },
        tipprospecdetalhe: '',
        empresa: {
            empid: 0
        }
    },
    size: 'lg'
}

/*const model_prospect_hasempresa = {
    ...model_prospect,
    api_get: '/prospects?hasEmpresa=1',
}*/

export const model_aula = {
    icon: <TfiAgenda />,
    name: 'Aula',
    key: 'aulaid',
    api_get: '/aulas',
    api_post: '/aula',
    api_delete: '/aula',
    inactive_field: 'aulaativo',
    description_field: 'aulainicio',
    inputs: [
        { id: 'aulaid', label: 'Código', type: 'text', md: 2, disabled: true },
        { id: 'turma.turmaid', label: 'Turma', type: 'select', md: 7, required: true, model: model_turma },
        { id: 'aulavagas', label: 'Vagas', type: 'number', md: 3, required: true },
        { id: 'aulatipo', label: 'Tipo', type: 'select', md: 4, required: true, options: options_tipo_aula },
        { id: 'auladatainicio', label: 'Data Início', type: 'date', md: 4, required: true },
        { id: 'aulainicio', label: 'Início', type: 'time', md: 4, required: true },
        { id: 'auladias', label: 'Dias', type: 'week', md: 12 },
    ],
    base: {
        aulaid: 0,
        turma: {
            turmaid: 0
        },
        aulaativo: 1
    }
}

export const model_aula_dia = {
    ...model_aula,
    api_get: '/aulasDisponivelDia',
    description_field: 'auladescricao',
    params_required: true,
}

export const model_log = {
    icon: <FaRegNewspaper />,
    name: 'Logs',
    key: 'logid',
    description_field: 'loglog',
    api_get: '/plataforma/logs'
}

export const model_lead = {
    icon: <MdSupportAgent />,
    name: 'Leads',
    key: 'leadid',
    description_fields: 'leadnome',
    api_get: '/leads',
    api_post: '/lead',
    api_delete: '/lead',
    hasEmpresa: true,
    inputs: [
        { id: 'leadnome', label: 'Nome', type: 'text', md: 12, required: true, maxLength: 100 },
        { id: 'leadcontato', label: 'Telefone', type: 'text', md: 6, model: 'phone' },
        { id: 'leadretorno', label: 'Retorno', type: 'datetime-local', md: 6 },
        { id: 'tipoProspeccao.tipprospecid', label: 'Tipo Prospecção', type: 'select', md: 12, required: true, model: model_tipo_prospeccao },
        { id: 'leadobs', label: 'Observação', type: 'text', md: 12, textarea: true, rows: 3, maxLength: 500 },
    ],
    base: {
        empid: 0,
        leadid: 0,
        leadnome: '',
        leadcontato: '',
        leadretorno: '',
        tipoProspeccao: {
            tipprospecid: 0
        },
        leadobs: '',
        leadsituacao: 1
    }
}

function setFilterAula(turma: any, dia: any, refs: any) {
    if (turma !== '')
        refs.current['aula.aulaid'].setFilter('?turmaid=' + turma + '&auladatainicio=' + dia)
}

function changeDia(_newValue: any, refs: any) {
    if ((_newValue) && (refs.current['turmaid'].getValue()))
        setFilterAula(refs.current['turmaid'].getValue(), _newValue, refs)
    else
        setFilterAula('', '', refs)
}

function changeTurma(_newValue: any, refs: any) {
    if ((_newValue.value > 0) && (refs.current['agendadia'].getValue()))
        setFilterAula(_newValue.value, refs.current['agendadia'].getValue(), refs)
    else
        setFilterAula('', '', refs)
}

export const model_agenda = {
    icon: <GiJumpingRope />,
    name: 'Agenda',
    key: 'agendaid',
    api_get: '/agendas',
    api_post: '/agenda',
    inputs: [
        { id: 'prospect.prospid', label: 'Prospect', type: 'select', md: 12, required: true, model: model_prospect },
        { id: 'agendadia', label: 'Data', type: 'date', md: 4, required: true, onAfterChange: changeDia },
        { id: 'turmaid', label: 'Turma', type: 'select', md: 8, required: true, model: model_turma, onAfterChange: changeTurma },
        { id: 'aula.aulaid', label: 'Aula', type: 'select', md: 12, required: true, model: model_aula_dia }
    ],
    base: {
        agendaid: 0,
        prospect: {
            prospid: 0
        },
        agendadia: '',
        aula: {
            aulaid: 0
        },
        agendapresente: 0
    }
}

export const model_motivo = {
    icon: <BsQuestionOctagonFill />,
    name: 'Motivo',
    key: 'motid',
    inactive_field: 'motativo',
    description_field: 'motdescricao',
    api_get: '/motivos',
    api_post: '/motivo',
    api_delete: '/motivo',
    inputs: [
        { id: 'motid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'motdescricao', label: 'Descrição', type: 'text', md: 9, required: true, maxLength: 200 },
    ],
    base: {
        motid: 0,
        motdescricao: '',
        mottipo: 0,
        motativo: 1
    }
}

export const model_feedback = {
    icon: <BiMessageEdit />,
    name: 'Feedback',
    key: 'sacid',
    api_get: 'feedbacks',
    inputs: [],
}

export const model_docao = {
    icon: <FaRegNewspaper />,
    name: 'Doações',
    key: 'doacaoid',
    description_field: 'doacaonome',
    api_get: '/doacoes'
}

export const model_prospect_naoconvertido = {
    name: 'Prospect Não Convertido',
    api_post: '/prospect/naoConvertido',
    inputs: [
        { id: 'motid', label: 'Motivo', type: 'select', md: 12, model: model_motivo, required: true },
        { id: 'contprospobservacao', label: 'Observação', type: 'text', md: 12, textarea: true, rows: 3, maxLength: 200 }
    ],
    base: {}
}

export const model_prospect_emespera = {
    name: 'Prospect em Espera',
    api_post: '/prospect/emEspera',
    inputs: [{ id: 'contprospobservacao', label: 'Observação', type: 'text', md: 12, required: true, textarea: true, rows: 3, maxLength: 200 }],
    base: {}
}

export const model_lead_detalhe = {
    name: 'Lead Observações',
    key: 'leaditemid',
    subKey: 'leadid',
    api_get: '/lead/detalhes',
    api_post: '/leadItem',
    api_delete: '/leadItem',
    hasEmpresa: true,
    inputs: [{
        id: 'leaditemcomentario', label: 'Observação', type: 'text', md: 12, required: true, textarea: true, rows: 3, maxLength: 500
    }],
    base: {}
}

export const model_lead_enrolado = {
    name: 'Lead Enrolado',
    api_post: '/lead/pausado',
    inputs: [{ id: 'leadmotivo', label: 'Motivo', type: 'text', md: 12, required: true, textarea: true, rows: 3, maxLength: 200 }],
    base: {}
}

export const model_lead_curioso = {
    name: 'Lead Curioso',
    api_post: '/lead/curioso',
    inputs: [{ id: 'leadmotivo', label: 'Motivo', type: 'text', md: 12, required: true, textarea: true, rows: 3, maxLength: 200 }],
    base: {}
}

export const model_usuario_presenca = {
    name: 'Usuário Presença',
    api_post: '/agenda/alteraPresenca',
    inputs: [{ id: 'usuid', label: 'Professor', type: 'select', md: 12, required: true, model: model_usuario, isMulti: true }],
    base: {}
}

export const model_controle_prospeccao = {
    name: 'Controle Prospecção',
    api_get: '/controlesProspeccao'
}

export const model_empresa = {
    name: 'Empresa',
    key: 'empid',
    api_get: '/empresas',
    description_field: 'empnome',
}

export const model_report_faltas = {
    icon: <LuAlertTriangle />,
    name: 'Relatório de Faltas',
    api_get: '/agendas/faltas'
}

export const model_metodo = {
    icon: <FaLocationDot />,
    name: 'Método',
    key: 'metodoid',
    description_field: 'metodotitulo',
    inactive_field: 'metodoativo',
    api_get: '/metodos',
    api_post: '/metodo',
    api_delete: '/metodo',
    inputs: [
        { id: 'metodoid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'metodotitulo', label: 'Título', type: 'text', md: 9, required: true, maxLength: 100 },
        { id: 'metododescricao', label: 'Descrição', type: 'text', md: 12, textarea: true, rows: 2, maxLength: 100 },
        { id: 'metodocor', label: 'Cor', type: 'color', md: 12, required: true, maxLength: 20 },
    ],
    base: {
        metodoid: 0,
        metodotitulo: '',
        metododescricao: '',
        metodocor: '',
        metodoativo: 1
    }
}

export const model_exercicio = {
    icon: <FaPersonRunning />,
    name: 'Exercício',
    key: 'exercid',
    description_field: 'exercdescricao',
    inactive_field: 'exercativo',
    api_get: '/exercicios',
    api_post: '/exercicio',
    api_delete: '/exercicio',
    inputs: [
        { id: 'exercid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'exercdescricao', label: 'Descrição', type: 'text', md: 9, maxLength: 100 },
    ],
    base: {
        exercid: 0,
        exercdescricao: '',
        exercativo: 1
    }
}

export const model_especificacao = {
    icon: <FaPersonRunning />,
    name: 'Especificação',
    key: 'especid',
    description_field: 'especdescricao',
    inactive_field: 'especativo',
    api_get: '/especificacoes',
    api_post: '/especificacao',
    api_delete: '/especificacao',
    inputs: [
        { id: 'especid', label: 'Código', type: 'number', disabled: true, md: 3 },
        { id: 'especdescricao', label: 'Descrição', type: 'text', md: 9, maxLength: 100 },
        { id: 'exercicio.exercid', label: 'Exercício', type: 'select', md: 12, required: true, model: model_exercicio }
    ],
    base: {
        especid: 0,
        especdescricao: '',
        exercicio: {
            exercid: 0
        },
        especativo: 1
    }
}

export const model_historico_agendamento = {
    icon: <TbHistoryToggle />,
    name: 'Histórico Agendamento',
    api_get: '/prospect/agendas',
}