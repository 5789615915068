import { Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { VbSelect } from "../../../components/VbSelect";
import { VbInput } from "../../../components/VbInput";
import { model_empresa, model_modalidade } from "../../../models";
import moment from "moment";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { formatDataCompare, getLocalStorage, setLocalStorage } from "../../../commons/utils";

export const FiltroConvertidosProfessor = forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const [data, setData] = useState<any>();

    const refUnidade = useRef<any>();
    const refConversaoInicial = useRef<any>();
    const refConversaoFinal = useRef<any>();
    const refModalidade = useRef<any>();

    function refresh() {
        change(true);
    }

    useImperativeHandle(ref, () => ({
        refresh
    }))

    async function getDados(refresh: boolean, cadastro_inicial?: any, cadastro_final?: any, modalidades?: any, unidades?: any) {
        let _data: any;

        if (!refresh && data)
            _data = data;
        else {
            await auth.get('/dashboard/convertidosPorProfessor')
                .then((response: any) => {
                    setData(response.data.dados);
                    _data = response.data.dados;
                })
                .catch((error: any) => {
                    console.log(error)
                    toast.error('Erro ao executar a operação.');
                })
        }

        let _convertido_inicial = cadastro_inicial;
        let _convertido_final = cadastro_final;
        let _modalidades = modalidades;
        let _unidades = unidades;

        if (!_modalidades)
            _modalidades = refModalidade.current.getValue();

        if (!_convertido_inicial)
            _convertido_inicial = refConversaoInicial.current.getValue();

        if (!_convertido_final)
            _convertido_final = refConversaoFinal.current.getValue();

        if (!_unidades)
            _unidades = refUnidade.current.getValue();

        if (_modalidades && (_modalidades.length > 0)) {
            const modalidValues = _modalidades.map((modalidade: any) => modalidade.value);
            _data = _data.filter((item: any) => modalidValues.includes(item.modalidid));
        }

        if (_unidades && (_unidades.length > 0)) {
            const unididVavlues = _unidades.map((unidade: any) => unidade.value);
            _data = _data.filter((item: any) => unididVavlues.includes(item.empid));
        }

        if (_convertido_inicial)
            _data = _data.filter((item: any) => formatDataCompare(item.contprospatualizacao) >= _convertido_inicial);

        if (_convertido_final)
            _data = _data.filter((item: any) => formatDataCompare(item.contprospatualizacao) <= _convertido_final);

        // Armazena os filtros 
        setLocalStorage('modalidades_dcp', _modalidades ?? null);
        setLocalStorage('convertido_inicial_dcp', _convertido_inicial ?? null);
        setLocalStorage('convertido_final_dcp', _convertido_final ?? null);
        setLocalStorage('unidades_dcp', _unidades ?? null);

        return _data;
    }

    function change(refresh: boolean, iCadastro?: any, fCadastro?: any, modalidades?: any, unidades?: any) {
        getDados(refresh, iCadastro, fCadastro, modalidades, unidades).then((data: any) => {
            if (props.onGetData)
                props.onGetData(data);
        })
    }

    function changeCadastroInicial(_newValue: any) { change(false, _newValue) }
    function changeCadastroFinal(_newValue: any) { change(false, null, _newValue) }
    function changeModalidade(_newValue: any) { change(false, null, null, _newValue) }
    function changeUnidade(_newValue: any) { change(false, null, null, null, _newValue) }

    useEffect(() => {
        change(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={2} style={{ ...props.style }}>
            <Grid item xs={4}>
                <VbSelect ref={refUnidade} id='unidade' label='Unidade' model={model_empresa} onAfterChange={changeUnidade} isMulti={true} defaultValue={getLocalStorage('unidades_dcp')} />
            </Grid>
            <Grid item xs={4}>
                <VbSelect ref={refModalidade} id='modalidade' label='Modalidade' model={model_modalidade} onAfterChange={changeModalidade} isMulti={true} defaultValue={getLocalStorage('modalidades_dcp')} />
            </Grid>
            <Grid item xs={2}>
                <VbInput ref={refConversaoInicial} id="ftconvinicial" type="date" label="Conversão Inicial" defaultValue={getLocalStorage('cadastro_inicial_dcp') ? getLocalStorage('cadastro_inicial_dcp') : moment().format('YYYY-MM-01')} onAfterChange={changeCadastroInicial} />
            </Grid>
            <Grid item xs={2}>
                <VbInput ref={refConversaoFinal} id="dtconvfinal" type="date" label="Conversão Final" defaultValue={moment().format('YYYY-MM-DD')} onAfterChange={changeCadastroFinal} />
            </Grid>
        </Grid>
    )
})