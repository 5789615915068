import 'primeicons/primeicons.css';
import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
import 'primereact/resources/primereact.css';
import './styles.css';
import { MdOutlineRefresh } from "react-icons/md";
import React, { useState, useMemo } from 'react';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { VbInput } from '../VbInput';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { ButtonFooter } from '../VbButtonDataTable';
import { Tooltip } from '@mui/material';

const MAX_ROWS = 50;

export const LabelDataTable = (props: any) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: props.color, borderRadius: '4px', padding: '2px', fontSize: '12px', fontWeight: 500 }}>
            {props.children}
        </div>
    )
}

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
`

interface VbDataTableProps extends DataTableProps {
    refresh: any;
    columns?: any;
}

export const VbDataTable: React.FC<VbDataTableProps> = ({ refresh, ...props }) => {
    const [globalFilter, setGlobalFilter] = useState<any>('');

    const filteredData = useMemo(() => {
        if (!props.value) {
            return [];
        }

        const containsFilter: any = (value: any, filter: any) => {
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some((nestedValue) =>
                    containsFilter(nestedValue, filter)
                );
            }

            return String(value).toLowerCase().includes(filter.toLowerCase());
        };

        return props.value.filter((item) =>
            containsFilter(item, globalFilter)
        );
    }, [props.value, globalFilter]);

    return (
        <DataTable
            {...props}
            header={
                <Header>
                    <Col>
                        <VbInput id="search" type="text" placeholder="Pesquisar" size="md" search onAfterChange={(value: any) => setGlobalFilter(value)} />
                    </Col>
                </Header>
            }
            paginatorRight={
                <Tooltip title="Recarregar">
                    <ButtonFooter onClick={() => refresh()}>
                        <MdOutlineRefresh />
                    </ButtonFooter>
                </Tooltip>
            }
            paginatorLeft={<></>}
            rowHover
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
            resizableColumns
            emptyMessage="Nenhum registro encontrado."
            rows={MAX_ROWS}
            paginatorTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                    : "CurrentPageReport"
            }
            currentPageReportTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "{first} à {last} de {totalRecords}"
                    : "{totalRecords} Registros"
            }
            expandedRowIcon="pi pi-angle-down"
            collapsedRowIcon="pi pi-angle-right"
            paginator={true}
            scrollHeight={props.scrollHeight ? props.scrollHeight : '100%'}
            sortField={props.sortField ? props.sortField : 'name'}
            sortOrder={props.sortOrder ? props.sortOrder : 1}
            value={filteredData}
        />
    );
};
