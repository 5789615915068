import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_tipo_prospeccao } from "../../../../models";

export const TipoProspeccao = () => {
    function getLabelIndicacao(value: any) {
        return (
            <div>
                {value === 1 ? 'Sim' : 'Não'}
            </div>
        )
    }

    const columns = [
        { field: 'tipprospecid', header: 'Código' },
        { field: 'tipprospecdescricao', header: 'Descrição' },
        { field: 'tipprospecindicaluno', header: 'Indicação Aluno', body: (e: any) => getLabelIndicacao(e.tipprospecindicaluno) },
        { field: 'tipprospecindicterceiro', header: 'Indicação Terceiro', body: (e: any) => getLabelIndicacao(e.tipprospecindicterceiro) },
        { field: 'tipprospecativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_tipo_prospeccao} columns={columns} />
    )
}