import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { VbFormLabel } from "../VbControls";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { COLOR_PRIMARY } from "../../theme/styles";
import { toast } from "react-toastify";
import Select from 'react-select';
import "./styles.css";

const customStyles = {
    container: (baseStyles: any) => ({
        ...baseStyles,
        width: '100%'
    }),
    control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        backgroundColor: 'transparent',
        minHeight: '0px',
        width: '100%',
        border: 'none',
        boxShadow: 'none'
    }),
    dropdownIndicator: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: '2px',
        paddingBottom: '2px',
    }),
    clearIndicator: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: '2px',
        paddingBottom: '2px',
    }),
    valueContainer: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: '4px',
        paddingBottom: '4px',

    }),
    input: (baseStyles: any) => ({
        ...baseStyles,
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0
    }),
    indicatorSeparator: (baseStyles: any) => ({
        ...baseStyles,
        marginTop: '4px',
        marginBottom: '4px',
        backgroundColor: '#495057'
    }),
    menu: (baseStyles: any) => ({
        ...baseStyles,
        backgroundColor: '#212529',
        border: '1px solid white',
        left: 0,
        zIndex: 1000
    }),
    option: (baseStyles: any, state: any) => (
        {
            ...baseStyles,
            backgroundColor: state.isSelected ? COLOR_PRIMARY : 'transparent',
            '&:hover': {
                backgroundColor: state.isSelected ? COLOR_PRIMARY : 'rgba(255, 255, 255, .1)'
            }
        }),
    singleValue: (baseStyles: any) => ({
        ...baseStyles,
        color: '#dee2e6',
        fontSize: '0.875rem'
    }),
    multiValue: (baseStyles: any) => ({
        ...baseStyles,
        margin: 0,
        marginRight: '4px'
    }),
    multiValueLabel: (baseStyles: any) => ({
        ...baseStyles,
        padding: 0
    }),
    multiValueRemove: (baseStyles: any) => ({
        ...baseStyles,
        color: '#212529',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, .1)',
            color: '#212529',
        },
    })
};

interface VbSelectProps {
    ref: any;
    id: any;
    defaultValue?: any;
    options?: any;
    label?: String;
    md?: any;
    required?: any;
    model?: any;
    size?: any;
    validated?: any;
    disabled?: any;
    onAfterChange?: any;
    params_required?: any;
    defaultEmpId?: any;
    isMulti?: any;
}

export const VbSelect: React.FC<VbSelectProps> = forwardRef((props: VbSelectProps, ref: any) => {
    const auth = useAuth();

    const selectRef = useRef<any>(null);

    const [options, setOptions] = useState<any[]>();
    const [value, setValue] = useState<any>();
    const [loading, setLoading] = useState<any>(props.options || props.model.params_required ? false : true);
    const [params, setParams] = useState<any>('');

    function getValue() {
        if (props.isMulti)
            return value
        else
            return value?.value
    }

    function getId() {
        return props.id
    }

    function validate() {
        const result = props.required ? (value !== '') : true;
        return result
    }

    function setFilter(_filter: any) {
        setValue(null);
        setParams(_filter);
        apiGet(_filter);
    }

    function getEmpId() {
        return value.empid
    }

    function getModel() {
        return props.model
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        getId,
        validate,
        setFilter,
        getEmpId,
        getModel
    }));

    async function apiGet(_filter?: any) {
        await auth.get(props.model.api_get + (_filter !== undefined ? _filter : '')).then((response: any) => {
            const dados = response.data.dados;

            const _options = dados.map((item: any) => ({
                value: props.model.key.split('.').length > 1 ? item[props.model.key.split('.')[0]][props.model.key.split('.')[1]] : item[props.model.key],
                label: props.model.description_field.split('.').length > 1 ?
                    item[props.model.description_field.split('.')[0]][props.model.description_field.split('.')[1]] :
                    item[props.model.description_field],
                empid: props.model.hasEmpresa ? item.empresa.empid : 0
            }));

            setOptions(_options);
            setLoading(false);
        }).catch((error: any) => {
            console.log(error);
            toast.error('Não foi possível carregar os valores do campo ' + props.label)
        })
    }

    useEffect(() => {
        if ((props.defaultValue !== '') && selectRef.current && (options || props.options) && (selectRef.current.getValue().length === 0)) {
            var _defaultOption: any;

            if (options)
                _defaultOption = options.find(option => ((String(option.value) === String(props.defaultValue)) && ((props.defaultEmpId === 0) || (props.defaultEmpId === undefined) || (option.empid === props.defaultEmpId))))
            else
                _defaultOption = props.options.find((option: any) => option.value === props.defaultValue);

            if (((props.defaultValue) && (props.isMulti)) || (_defaultOption)) {
                if (props.isMulti)
                    setValue(props.defaultValue)
                else
                    setValue(_defaultOption);

                if (props.onAfterChange)
                    props.onAfterChange(_defaultOption);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, props.options]);

    useMemo(() => {
        if ((!props.options) && (props.model) && (props.model.api_get) && (!props.model.params_required || params)) {
            apiGet();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.model]);

    const handleOnChange = (e: any) => {
        if (e)
            setValue(e);

        if (props.onAfterChange)
            props.onAfterChange(e);
    };

    return (
        <Form.Group as={Col} md={props?.md ? props.md : "12"}>
            {props.label && <VbFormLabel>{(props?.required ? '*' : '') + props.label}</VbFormLabel>}
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                <Form.Control
                    className="custom_select"
                    ref={selectRef}
                    isInvalid={props.validated && props.required && !value}
                    isValid={props.validated && value}
                    as={Select}
                    onChange={handleOnChange}
                    placeholder=""
                    isLoading={loading}
                    options={props.options ? props.options : options}
                    required={props.required}
                    value={value}
                    isDisabled={props.disabled}
                    styles={customStyles}
                    isMulti={props.isMulti}
                />
            </InputGroup>
        </Form.Group>
    );
});
