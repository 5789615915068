import { useRef } from "react";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_lead } from "../../../../models";
import { COLOR_ORANGE } from "../../../../theme/styles";
import SubjectIcon from '@mui/icons-material/Subject';
import { LeadItem } from "../LeadItem";

export const Lead = () => {
    const refDetalhes = useRef<any>();

    function functions(row: any) {
        return ([
            {
                label: 'Observações',
                click: () => { refDetalhes.current.openModal(row.empid, row.leadid) },
                icon: <SubjectIcon />, color: COLOR_ORANGE
            },

        ])
    }

    const columns = [
        { field: 'leadid', header: 'Código' },
        { field: 'leadnome', header: 'Nome' },
        { field: 'leadcontato', header: 'Contato' },
        { field: 'tipoProspeccao.tipprospecdescricao', header: 'Tipo Prospecção' },
        { field: 'leadobs', header: 'Observação Cadastro', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <>
            <ConsultaPadrao model={model_lead} columns={columns} functions={functions} />
            <LeadItem ref={refDetalhes} />
        </>
    )
}