import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_exercicio } from "../../../../models";

export const Exercicio = () => {
    const columns = [
        { field: 'exercid', header: 'Código' },
        { field: 'exercdescricao', header: 'Descrição' },
        { field: 'exercativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    return (
        <ConsultaPadrao model={model_exercicio} columns={columns} />
    )
}