import React from 'react';
import FullCalendar from '@fullcalendar/react';
import { CalendarOptions } from '@fullcalendar/core';
import "./styles.css";


class Calendar extends React.Component<CalendarOptions> {
    render() {
        return (
            <div style={{ padding: '15px' }}>
                <FullCalendar {... this.props} />
            </div>
        );
    }
}

export default Calendar;
