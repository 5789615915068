import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const CardTable = styled(Card)`
    border-radius: 4px;
    background-color: #2B3035;
`

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 7px;
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    font-size: 22px;
    margin-right: 6px;
`

export const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin-left: 1rem;
`

